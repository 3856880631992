import React from 'react';
import PT from 'prop-types';
import { MiniMediaGrid } from './styled-components/structures';
import { HTMLContent } from './Content';
import {
  MediaListHeading,
  MediaListContent,
} from './styled-components/elements';

const MemberUpdate = ({ memberUpdate, isActive, handleClickUpdate }) => {
  console.log(memberUpdate);
  return (
    <MiniMediaGrid>
      <MediaListHeading
        clickable
        onClick={() => {
          handleClickUpdate(memberUpdate.id);
        }}
      >
        {memberUpdate.frontmatter.title}
      </MediaListHeading>
      {isActive && (
        <MediaListContent>
          <HTMLContent content={memberUpdate.html} />
        </MediaListContent>
      )}
    </MiniMediaGrid>
  );
};

MemberUpdate.propTypes = {
  memberUpdate: PT.object.isRequired,
};

export default MemberUpdate;
